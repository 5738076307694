.search-result-header-v1 {

	.found {
		font-weight: 400;
	}

	.srt-bl-top {
		min-height: 43px;

		.flex-align-center {

			.btn-filter {
				font-size: var(--font-size-md);
				padding-right: 10px;
				display: inline-block;
			}
		}
	}

	.tab-btn {
		display: flex;
		align-items: center;
		border-bottom: 3px solid var(--color-primary);
		margin-bottom: 30px;

		a {
			display: inline-block;
			max-width: 180px;
			background-color: var(--color-secondary);
			color: #fff;
			border-radius: 8px 8px 0 0;
			margin: 0 3px 0 0;
			border: none;

			&.active {
				background-color: var(--color-primary) !important;
			}
		}
	}

	.srt-bl-top {
		padding-bottom: 10px;
	}

}