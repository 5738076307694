.faq-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.inner-banner {
		&.faq {
			background-image: url(RESOURCE/img/inner-banner-faq.webp);
			background-position: center;
		}
	}

	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

	.accordion-button {
		font-size: 20px;
	}

	#accordion {

		.card {
			background-color: transparent;
			border: 1px solid var(--color-grey-normal);

			.card-header {
				background-color: var(--faq-title-bg-color);
				border-bottom: none;
				padding: 0;
			}

		}

		.btn-link {
			font-weight: normal;
			color: var(--faq-title-font-color);
			text-decoration: none;
			width: 100%;
			text-align: left;
			font-size: var(--font-size-main);
			padding: .5rem 1rem;
			position: relative;

			&[aria-expanded="true"] {
				&:after {
					transform: rotate(180deg);
				}

			}

			&:after {
				content: "\f107";
				position: absolute;
				right: 15px;
				top: 10px;
				transition: all 0.2s ease;
				transform: rotate(0deg);
				font-family: "Font Awesome 5 Free";
				font-weight: 700;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;



			}

			&:hover {
				color: var(--faq-title-font-color);
				text-decoration: none;
			}
		}
	}

}