.ueber-uns-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.inner-banner {
		&.team {
			background-image: url(RESOURCE/img/inner-banner-team.webp);
			background-position: center;
		}
	}

	.paragraph-blue {
		color: #1a79bb;
	}


	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;

		.jobs-link {
			text-decoration: none;
		}
	}

	.image-cover {
		&.big {
			height: 500px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	a {
		text-decoration: underline;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);



		.name {
			font-weight: 600;
			color: var(--font-color-main);
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
			color: var(--font-color-main);
		}

	}


}