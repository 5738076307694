.impressum-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.inner-banner {
		&.impressum-banner {
			background-image: url(RESOURCE/img/inner-banner-impressum.webp);
			background-position: center;
		}
	}

}