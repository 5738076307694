.index-page-layout-v1 {

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.bg-blue-strip {
		.btn-primary {
			.fa-arrow-right {
				opacity: .4;
				margin-left: 15px;
			}
		}

	}

	.die-wfv-text {
		text-align: center;
		margin: 20px 0;
		line-height: 1;

		p {
			margin: 0;
		}

		.heading-num {
			font-size: 48px;
			color: var(--color-primary);
			font-weight: bold;
		}
	}

	.feature-bl {
		position: relative;
		z-index: 1;
		border-radius: 15px 15px 0 0;
		top: -12px;

		.highlight {
			.icon-blue {
				filter: brightness(0) saturate(100%) invert(19%) sepia(93%) saturate(4197%) hue-rotate(191deg) brightness(99%) contrast(101%);
			}

			.icon {
				height: 70px;
				width: 70px;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center;

				&.feature-erfahrung {
					background-image: url(RESOURCE/img/feature-erfahrung.png);
				}

				&.feature-guest {
					background-image: url(RESOURCE/img/feature-guest.png);
				}

				&.feature-feriendomizile {
					background-image: url(RESOURCE/img/feature-feriendomizile.png);
				}
			}
		}

	}

	.feature-service-bl {
		h3 {
			margin-top: 20px;
			color: #fff;
			position: relative;

			@media (max-width:1300px) and (min-width:993px) {
				font-size: 20px;
			}
		}

		p {
			min-height: 170px;
			font-size: 16px;
			color: #fff;
			line-height: 24px;
			position: relative;
			margin: 0 auto;
		}

		.btn-primary {
			position: relative;
		}

		.feature-service-block {
			position: relative;
			width: 100%;
			margin-bottom: 30px;
			border-radius: var(--border-radius);
			overflow: hidden;
			position: relative;
			inset: 0;
			transform: scale(1);
			transition: all ease .2s;

			&:hover {
				transform: scale(1.05);

				@media (min-width:993px) {
					.feat-title {
						bottom: -100px;
					}

					.feature-content {
						bottom: 0;
					}

					.feature-content,
					.feat-text-link {
						visibility: visible;
						opacity: 1;
					}
				}
			}


			.feat-link {
				position: absolute;
				inset: 0;
				z-index: 2;
			}

			.feat-text-link {
				position: absolute;
				bottom: 40px;
				right: 50px;
				transition: bottom 0.3s ease-in-out;
				visibility: hidden;
				opacity: 0;
				color: #fff;
				text-transform: capitalize;
				font-size: 18px;
				font-weight: bold;


				@media (max-width:992px) {
					visibility: visible;
					opacity: 1;
				}
			}

			.feat-title {
				transition: all 0.3s ease-in-out;
				display: inline-block;
				position: absolute;
				z-index: 1;
				color: var(--btn-primary-font-color);
				background-color: var(--btn-primary-bg-color);
				left: 30px;
				bottom: 30px;
				transition: bottom 0.3s ease-in-out;
				padding: 10px 20px;
				font-weight: bold;

				@media (max-width:992px) {
					display: none;
				}
			}

			.feature-content {
				background-color: rgba(26, 121, 187, .85);
				padding: 20px;
				position: absolute;
				height: 100%;
				transition: all 0.3s ease-in-out;
				bottom: -100%;
				visibility: hidden;
				opacity: 0;

				@media (max-width:992px) {
					background-color: rgba(26, 121, 187, .75) !important;
					bottom: inherit;
					bottom: 0 !important;
					visibility: visible !important;
					opacity: 1 !important;
				}
			}

			.img-block {
				height: 335px;

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

		}

		.grey-border {
			border: 1px solid #e3e3e3;
		}

	}


	h2 {
		text-align: center;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 20px 10px;
			position: relative;

			@media (max-width:767px) {
				padding: 10px;
			}


			.title {
				font-size: 20px;
				margin-bottom: 20px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}