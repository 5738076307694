.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.inner-banner {
		&.kontakt {
			background-image: url(RESOURCE/img/inner-banner-kontakt.webp);
		}
	}

	.contact-bl {
		display: flex;
		margin-bottom: 5px;
		position: relative;
		padding-left: 40px;
		min-height: 40px;
		align-items: center;
		font-size: var(--font-size-md);

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-primary);
			}
		}


		i {
			color: var(--color-white);
			background-color: var(--color-secondary);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 16px;
		}


	}
}