.navbar-v1 {
	.container-xl {
		@media (min-width: 1081px) and (max-width: 1300px) {
			max-width: 1080px;
		}
	}
}

.extended-menu {
	@media (max-width:992px) {
		display: none;
	}

	.navbar-v1 {
		.header-logo {
			display: inline-block;
			padding: 15px 40px;
			border-radius: 0 0 15px 15px;
			background-color: var(--color-white);
			top: 0;
			position: absolute;

			.logo {
				width: 130px !important;

				@media(max-width:992px) {
					width: 80px !important;
					padding: 0;
				}
			}
		}

		.btn-search,
		.btn-bar {
			display: none;
		}

		.dropdown-toggle:after {
			display: inline-block;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid;
			border-right: 0.3em solid transparent;
			border-bottom: 0;
			border-left: 0.3em solid transparent;
		}

		.icon-menu {
			display: none;
		}

		.icons-nav {

			.btn-call,
			.search-unit-ul {
				.dropdown-toggle:after {
					display: none;
				}

				.dropdown-menu {
					padding: 10px;
					position: absolute !important;
					right: 0 !important;

					@media (max-width:992px) {
						top: 65px !important;

					}

					.phoneLink {
						text-decoration: underline;
						cursor: pointer;

					}
				}
			}
		}

	}



	.inner-pages {
		.navbar-v1 {
			background-color: var(--color-white) !important;
			box-shadow: 0 3px 3px -3px grey;
		}
	}

	.dropdown,
	.double-links {
		&:hover {
			.dropdown-menu {
				display: block;
			}
		}
	}

	#header-fastnav-search {
		transition: top 0.5s ease;
		position: absolute;
		top: -100px;
		background-color: var(--color-grey-light);
		z-index: 10;
		right: 0px;

		&.show {
			top: var(--page-margin-top);
		}


		.search-unit-ul {
			max-width: 280px;
			padding: 8px 15px;
		}

		.input-search-unit {
			width: 250px;


			.form-control {
				padding: 5px 10px;
				width: 200px;

			}

			.form-control::placeholder {
				font-size: 0.875rem;
				color: #aaa;
				font-style: italic;
			}

			.btn {
				border-radius: 0 5px 5px 0;
			}
		}
	}




	.navbar-v1.affix,
	.navbar-v1 {
		padding: 15px 0;
		position: absolute;
		width: 100%;
		top: 0;
		border-width: 0 0 1px;
		height: 76px;

		z-index: 50;

		@media (max-width:992px) {
			position: fixed;
			height: 75px;
			background-color: var(--color-white) !important;

		}


		.fav-count {
			position: relative;

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: 7px;
				width: 18px;
				height: 18px;
				background: var(--color-secondary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;

				@media (min-width:1400px) {
					right: 6px;
				}

				@media (min-width:1800px) {
					right: 10px;
				}

				@media (max-width:1399px) {
					right: 4px;
				}

				@media (max-width:1199px) {
					right: 2px;
				}

				@media (max-width:992px) {
					right: -2px;
				}


				@media (max-width:767px) {
					width: 15px;
					height: 15px;
					line-height: 15px;
					right: -1px;
				}
			}
		}

		.tele-box,
		.navbar-v1.affix .search-unit-ul,
		.navbar-v1.affix .tele-box {
			position: absolute;
			right: 0;
			top: 77px;
			background-color: var(--color-grey-light);
			max-width: 280px;
			padding: 8px 32px;
			height: 40px;
			font-weight: 600;
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
		}



		.header-main {
			margin-top: 15px;

			.main-menu {
				float: right;

				@media (max-width:992px) {
					position: absolute;
					left: 0;
					top: 76px;
					background-color: var(--main-nav-mob-menu-bg-color);
					z-index: 5;
				}

				>ul {
					padding: 0;
					margin: 0;

					>li {
						float: left;
						display: inline-block;

						@media (min-width:1300px) {
							margin: 0 5px;
						}

						&.dropdown {
							.dropdown-toggle:after {
								top: 19px;
								right: 5px;
							}

							.dropdown-toggle {
								padding-right: 20px;
							}

						}

						&.double-links {
							@media (max-width:992px) {
								display: inline-flex;
								flex-wrap: wrap;
							}

							@media (min-width:993px) {
								position: relative;
								padding-right: 20px;
							}

							.dropdown-toggle:after {
								top: 10px;
								right: 0;
							}

							.suchen-btn {
								padding-right: 0;
							}

							.suchen-dropdown {
								position: absolute;

								@media (max-width:992px) {
									left: inherit;
									position: relative;
									padding-left: 0;
								}

								@media (min-width:993px) {
									right: 6px;
									top: 9px;
									padding: 10px;
									cursor: pointer;
								}
							}
						}

						&:hover {
							a {
								color: var(--main-nav-font-color-hover);
								text-decoration: none;
							}

							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}

						.dropdown-menu-right {
							@media (min-width:993px) {
								transform: inherit !important;
								left: inherit !important;
								right: 0 !important;
								top: 100% !important;
							}
						}

						@media (max-width:992px) {
							display: block;
							width: 100%;
						}

						&:last-child {
							margin-right: 0;
						}


						>a {
							color: var(--color-black);
							text-transform: var(--main-nav-text-transform);
							//font-size: var(--main-nav-font-size);
							//padding: 15px 1.2vW;
							font-size: 18px;
							padding: 15px;
							line-height: 1;
							display: block;
							font-weight: 400;

							@media (max-width: 1080px) {
								font-size: 14px;
							}

							@media (max-width: 991px) {
								font-size: var(--main-nav-font-size);
								color: var(--color-white);
							}

							@media (max-width:992px) {
								padding: 10px 15px;
								text-transform: capitalize;
								font-size: var(--main-nav-font-size);
								color: var(--main-nav-mob-font-color-hover);
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}

		.dropdown-menu {
			border-radius: 0;
			border: none;
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
			min-width: 200px;
			margin-top: -1px;

			@media (max-width: 992px) {
				width: 100% !important;
				max-height: 450px;
				overflow: scroll;
			}
		}

		.sub-nav {

			ul {
				list-style-type: none;
				padding-left: 10px;

				@media (min-width: 993px) {
					display: flex;
					flex-wrap: wrap;
					width: 250px;
				}

				@media (max-width: 992px) {
					width: 100%;
				}


				li {
					@media (min-width: 993px) {
						width: 100%;
					}

					a {
						padding: 10px 0;
						color: var(--main-sub-font-color) !important;
						display: block;
						font-size: 17px;

						@media (max-width:992px) {
							padding: 10px;
							font-weight: 400;
							text-transform: initial;
						}

						&:hover {
							color: var(--main-sub-font-color-hover) !important;
							text-decoration: none;

						}
					}

				}
			}


		}

		.icon {
			display: none;
			position: absolute;
			right: 10px;
			text-decoration: none;
			color: var(--color-secondary);
			font-size: 30px;

		}

		.navactive {
			@media (min-width:993px) {
				color: var(--main-nav-font-color-hover) !important;
				background-color: var(--main-nav-bg-hover) !important;
			}
		}

		.nav-row {


			.col-12 {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.icons-nav {
					display: flex;
					align-items: center;
					margin-bottom: 0;
					position: relative;

					@media screen and (max-width: 992px) {
						position: absolute;
						right: 45px;
						top: 10px;
					}

					li {
						&:hover {
							background-color: var(--color-white);
						}

						&.btn-fav {
							&:hover {
								background-color: transparent;
							}
						}

						&.btn-call {
							cursor: pointer;

							.dropdown-toggle:after {
								display: none;
							}

							@media(max-width:992px) {
								padding: 0 10px;
							}

						}

						.dropdown-menu-right {
							@media (min-width:993px) {
								transform: inherit !important;
								left: inherit !important;
								right: 0 !important;
								top: 45px !important;
							}
						}

						>a {
							color: var(--link-font-color);
						}

						a {
							padding: 10px 15px;
							display: inline-block;
							font-size: var(--main-nav-font-size);
							cursor: pointer;


							&.kontakt-link {
								padding: 0;
							}
						}
					}
				}
			}
		}


		@media screen and (max-width: 992px) {

			.top-nav {
				padding: 10px 0 8px;
				text-align: right;


				.input-search-unit {
					display: none;
				}
			}

			.header-logo {
				padding-left: 0px !important;
			}

			.nav-row {
				min-height: 50px !important;
			}

			.icon {
				display: block;
				bottom: 10px;
			}

			.dropdown-menu {
				position: relative !important;
				transform: none !important;
				width: 65px;
				border-top: none;
			}

			.main-menu {
				background-color: var(--nav-bg-color);
				padding: 0px 0;
				z-index: 50;
				display: none;
				width: 100%;
				border-bottom: 1px solid #61847a;
			}
		}

		@media screen and (max-width: 992px) {

			.top-nav {
				text-align: center;

				.input-search-unit {
					display: none;
				}
			}

			.header-main {

				.nav-row {
					min-height: 20px !important;
				}

				.icon {
					position: absolute;
					top: 11px;
					right: 10px;
				}
			}

		}

		@media screen and (min-width: 993px) {
			.main-menu {
				display: block !important;
			}
		}

	}

	.navbar-v1 {
		&.affix {
			background-color: var(--color-white) !important;
			box-shadow: 0 3px 3px -3px grey;
		}
	}

	@media (min-width:993px) {
		.navbar-v1 {
			&.affix-top {
				background-color: transparent !important;
				box-shadow: inherit;

				.logo {
					width: 120px !important;
					height: auto;
				}
			}

			&.affix {

				.logo {
					width: 80px;
					height: auto;


				}
			}
		}
	}


}

.inner-pages {
	.sticky-navbar {
		.navbar-v1 {
			&.affix-top {
				visibility: visible;
				opacity: 1;
				top: 0 !important;
				position: absolute !important;
			}
		}
	}
}

.sticky-navbar {
	.navbar-v1 {
		position: fixed !important;
		transition: all ease .5s;

		&.show {
			z-index: 7;
		}


		&.affix-top {
			top: -80px;
			visibility: hidden;
			opacity: 0;

			@media (max-width:992px) {
				visibility: visible;
				opacity: 1;
				top: 0 !important;
				position: relative !important;
			}

			.header-logo {
				display: inline-block;
				padding: 0;

				.logo {
					width: 80px !important;
				}
			}
		}

		&.affix {
			top: 0;
			visibility: visible;
			opacity: 1;

			.header-logo {
				display: inline-block;
				padding: 0;

				.logo {
					width: 80px !important;
				}
			}

			.nav-row {
				.col-12 {
					margin-top: -5px;

					.btn-primary {
						&.btn-suchen {
							display: block !important;

							@media(max-width: 345px) {
								display: none !important;
							}
						}
					}
				}
			}
		}

		.logo {
			width: 80px !important;
			padding: 0;
		}
	}



	.navbar-v1 {

		/*.container-xl {
		@media (min-width: 1300px) {
			width: 100%;
			max-width: inherit;
		}
	}*/



		.icons-nav {

			.btn-call,
			.search-unit-ul {
				.dropdown-toggle:after {
					display: none;
				}

				@media (max-width:992px) {
					padding: 0;
				}

				.dropdown-menu {
					box-shadow: 0 3px 3px -3px grey;

					@media (max-width:992px) {
						top: 65px !important;

					}

					.phoneLink {
						text-decoration: underline;
						cursor: pointer;

					}
				}
			}
		}

	}



	.inner-pages {
		.navbar-v1 {
			background-color: var(--color-white) !important;
			//box-shadow: 0 3px 3px -3px grey;
		}
	}

	#header-fastnav-search {
		transition: top 0.5s ease;
		position: absolute;
		top: -100px;
		background-color: var(--color-grey-light);
		z-index: 10;
		right: 0px;

		&.show {
			top: var(--page-margin-top);
		}


		.search-unit-ul {
			max-width: 280px;
			padding: 8px 15px;
		}

		.input-search-unit {
			width: 250px;


			.form-control {
				padding: 5px 10px;
				width: 200px;

			}

			.form-control::placeholder {
				font-size: 0.875rem;
				color: #aaa;
				font-style: italic;
			}

			.btn {
				border-radius: 0 5px 5px 0;
			}
		}
	}



	.navbar-v1.affix,
	.navbar-v1 {
		padding: 15px 0;
		position: absolute;
		width: 100%;
		top: 0;
		border-width: 0 0 1px;
		height: 76px;
		background-color: var(--color-white);
		z-index: 5;

		@media (max-width:992px) {
			position: absolute;
			height: 75px;
			background-color: var(--color-white) !important;

		}

		.btn-primary {
			&.btn-suchen {
				@media (min-width:993px) {
					display: none !important;
				}

				@media (max-width:340px) {
					display: none !important;
				}
			}
		}

		.fav-count {
			position: relative;

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: 7px;
				width: 18px;
				height: 18px;
				background: var(--color-secondary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;

				@media (min-width:1400px) {
					right: 6px;
				}

				@media (min-width:1800px) {
					right: 10px;
				}

				@media (max-width:1399px) {
					right: 4px;
				}

				@media (max-width:1199px) {
					right: 2px;
				}

				@media (max-width:992px) {
					right: -2px;
				}


				@media (max-width:767px) {
					width: 15px;
					height: 15px;
					line-height: 15px;
					right: -1px;
				}
			}
		}

		.tele-box,
		.navbar-v1.affix .search-unit-ul,
		.navbar-v1.affix .tele-box {
			position: absolute;
			right: 0;
			top: 77px;
			background-color: var(--color-grey-light);
			max-width: 280px;
			padding: 8px 32px;
			height: 40px;
			font-weight: 600;
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
		}



		.header-main {

			@media (max-width:992px) {
				.icons-nav {
					.btn-search {
						margin-right: 15px !important;
					}
				}
			}

			#logo {
				transition: all 0.3s ease;
				/* Füge eine Übergangsanimation hinzu */
			}

			.header-logo {
				position: absolute;
				z-index: 20;
				top: 9px;

				.logo {
					width: 80px;
					height: auto;
					transition: all .2s ease;

					@media (max-width:992px) {
						width: 77px;
						margin-left: 10px;
					}

				}

			}

			.main-menu {
				background-color: var(--nav-bg-color);
				padding: 70px 20px;
				z-index: 50;
				width: 100%;
				border-bottom: 1px solid #61847a;
				position: fixed;
				right: -100%;
				top: 0;
				background-color: var(--main-nav-mob-menu-bg-color);
				z-index: 50;
				max-width: 350px;
				height: 100vh !important;
				transition: all .35s;

				@media(max-width:992px) {
					box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
					padding: 50px 20px 40px 0;
				}

				>.container {
					position: relative;
				}

				@media(min-width:993px) {
					max-width: inherit;
					max-width: 100% !important;
				}



				.icon-menu {
					color: var(--color-primary);
					position: absolute;
					right: 0;
					top: -34px;

					.fa {
						font-weight: 400;
					}

				}

				&.active {
					right: 0;
				}

				>ul {
					padding: 0;
					margin: 0;
					display: flex;
					flex-wrap: wrap;
					max-width: 1280px;
					margin: 0 auto;

					>li {
						display: block;
						width: 100%;

						@media (min-width:993px) {
							width: 20%;
						}

						&:last-child {
							@media (min-width:993px) {
								border-right: none;

							}
						}

						@media (min-width:993px) {
							border-right: 1px solid var(--color-grey-normal);
							padding: 0 15px;
						}

						&.dropdown {
							.dropdown-toggle {
								@media(min-width:993px) {
									text-decoration: none;
								}
							}
						}

						&.double-links {

							.suchen-btn {
								&:hover {
									@media(min-width:993px) {
										text-decoration: underline !;
									}
								}
							}

							.dropdown-toggle {
								@media (min-width:993px) {
									display: none;
								}
							}


							.suchen-dropdown {
								position: absolute;

								left: inherit;
								padding-left: 0;
								right: 0;
								top: 0;
								padding: 10px;
								cursor: pointer;
							}
						}

						.dropdown-menu-right {
							@media (min-width:993px) {
								display: block !important;
								border: none;
							}
						}



						&:last-child {
							margin-right: 0;
						}


						>a {

							text-transform: var(--main-nav-text-transform);
							/*font-size: var(--main-nav-font-size);
						padding: 15px 1.2vW;*/
							font-size: var(--main-nav-font-size);
							padding: 15px 10px;
							line-height: 1;
							display: block;
							font-weight: 400;
							padding: 10px 15px;
							text-transform: capitalize;

							color: var(--color-black);


						}

						&.dropdown {

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}

		.dropdown-menu {
			@media(max-width:992px) {
				border-radius: 0;
				border: none;
				min-width: 200px;
				margin-top: -1px;
			}


		}

		.sub-nav {

			ul {
				list-style-type: none;
				padding-left: 10px;

				@media (min-width: 993px) {
					display: flex;
					flex-wrap: wrap;

				}

				@media (max-width: 992px) {
					width: 100%;
				}


				li {
					@media (min-width: 993px) {
						width: 100%;
					}

					a {
						padding: 10px 5px;
						color: var(--main-sub-font-color) !important;
						display: block;
						font-size: 17px;

						@media (max-width:992px) {
							padding: 6px;
							font-weight: 400;
							text-transform: initial;
						}

						&:hover {
							color: var(--main-sub-font-color-hover) !important;
							text-decoration: none;

						}
					}

				}
			}


		}

		.icon-menu {
			position: relative;
			text-decoration: none;
			color: var(--color-primary);
			font-size: 22px !important;

		}

		.navactive {
			@media (min-width:993px) {
				color: var(--main-nav-font-color-hover) !important;
				background-color: var(--main-nav-bg-hover) !important;
			}
		}

		.nav-row {


			.col-12 {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				margin-top: -5px;

				.icons-nav {
					display: flex;
					align-items: center;
					margin-bottom: 0;
					position: relative;

					@media screen and (max-width: 992px) {
						position: absolute;
						top: 10px;
					}

					li {
						position: relative;

						.dropdown-menu {
							&.show {
								position: absolute !important;
								padding: 10px;
								border-radius: 0;
								border: none;
								box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
								min-width: 200px;
								background-color: var(--color-grey-light);
								margin-top: -1px;
							}
						}

						&.btn-call {
							cursor: pointer;

							.dropdown-toggle:after {
								display: none;
							}

							.dropdown-toggle {

								&:after {
									right: 23px !important;
								}
							}

							@media(max-width:992px) {
								padding: 0;
							}

							a {
								&:hover {
									color: var(--color-grey-dark);
								}
							}
						}

						.dropdown-menu-right {
							@media (min-width:993px) {
								transform: inherit !important;
								left: inherit !important;
								right: 0 !important;
								top: 45px !important;
							}
						}

						a {
							padding: 12px 1.2vW;
							display: inline-block;
							font-size: var(--main-nav-font-size);
							cursor: pointer;

							&.kontakt-link {
								padding: 0;
							}
						}
					}
				}
			}
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			border-top: none;
		}

		@media screen and (max-width: 992px) {

			.top-nav {
				padding: 10px 0 8px;
				text-align: right;


				.input-search-unit {
					display: none;
				}
			}

			.header-logo {
				padding-left: 0px !important;

				@media(max-width:992px) {
					padding: 0 !important;
				}
			}

			.nav-row {
				min-height: 50px !important;
			}





		}

		@media screen and (max-width: 992px) {

			.top-nav {
				text-align: center;

				.input-search-unit {
					display: none;
				}
			}

			.header-main {

				.nav-row {
					min-height: 20px !important;
				}

				.icon {
					position: absolute;
					top: 11px;
					right: 10px;
				}
			}

		}


	}

	.navbar-v1 {
		&.affix {
			background-color: var(--color-white) !important;
			box-shadow: 0 3px 3px -3px grey;
		}
	}

	.navbar-v1 {}
}

.to-top {
	position: fixed;
	cursor: pointer;
	bottom: 15px;
	display: none;
	right: 10px;
	width: 32px;
	height: 32px;
	border-radius: var(--border-radius);
	background-color: var(--color-secondary);
	color: var(--color-white);
	z-index: 200;
	line-height: 32px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}