.unit-service-v1 {
	background-color: var(--color-secondary-light);
	margin-top: 15px;
	border-radius: var(--border-radius);

	.title {
		font-weight: 700;
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
			border-radius: var(--border-radius);

		}
	}

	.info {
		padding: 10px;
	}

	.fa {
		padding-right: 5px;
	}

	a {
		color: var(--font-color-main);

		&:hover {
			text-decoration: underline;
		}
	}


}

.service-btn {
	position: fixed;
	bottom: 70px;
	right: 15px;
	z-index: 99;

	@media (max-width:767px) {
		bottom: 15px;
	}

	.btn {
		font-size: 17px;
		border: 1px solid var(--color-white);
	}
}