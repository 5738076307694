.ferienanlagen-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 240px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.showFirst6 {
		.facility-outer {
			display: none;

			&:nth-child(-n+6) {
				display: block;
			}
		}
	}

	.facility-card {
		background-color: #fff;
		border: 1px solid var(--color-grey-normal);
		transition: all 0.2s;
		height: 100%;
		border-radius: var(--border-radius);
		margin-bottom: 30px;

		&:hover {
			@media(min-width:992px) {
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
				transform: scale(1.015);

			}
		}

		.facility-container {
			.img-col {
				position: relative;
				width: 100%;

				img {
					border-top-left-radius: var(--border-radius);
					border-top-right-radius: var(--border-radius);
				}
			}

			.facility-facts {
				padding: 0px 10px;

				.facility-title {
					padding-top: 10px;
				}

				.place {
					font-size: var(--font-size-md);

					.fa {
						color: var(--color-grey-dark);
					}
				}

				.button-row {
					padding: 20px 0px;
				}

			}

		}
	}
}