.regionen-v1 {
	/*margin-top: var(--page-margin-top);*/
	margin-top: var(--page-margin-top);
	/*padding-top: var(--page-padding-top);*/
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 170px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.inner-banner {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 10px;
			background-color: var(--color-white);
			border-radius: 15px 15px 0 0;
		}

		&.region-991,
		&.region-988,
		&.region-10619,
		&.region-11345,
		&.region-990,
		&.region-10618,
		&.region-992 {
			background-image: url(RESOURCE/img/inner-banner-rugen.webp);
		}
	}

	&.rugen-boxes {
		.region-content-bl {
			p {
				display: none;

				&:first-child {

					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			&.style2 {
				.region-bl {
					min-height: auto;
				}
			}
		}
	}

	#description {
		p {
			display: none;

			&:first-child {
				display: block;
			}
		}

		h2 {
			display: none;
		}

		&.show-all {
			margin-bottom: 50px;

			p {
				display: block;
			}

			h2 {
				display: block;
			}
		}
	}

	#showMoreLink {
		margin-bottom: 50px;
		text-align: center;
	}

	.inner-banner {
		background-position: center;

		&.region-980 {
			background-image: url(RESOURCE/img/inner-banner-borkum.webp);
		}

		&.region-981 {
			background-image: url(RESOURCE/img/inner-banner-juist.webp);
		}

		&.region-979 {
			background-image: url(RESOURCE/img/inner-banner-wangerooge.webp);
		}

		&.region-12530 {
			background-image: url(RESOURCE/img/inner-banner-langeoog1.webp);
		}

		&.region-991,
		&.region-4177,
		&.region-10619,
		&.region-11345,
		&.region-992 {
			background-image: url(RESOURCE/img/inner-banner-rugen.webp);
		}
	}

	.regicon7 {
		.region-img {
			background-size: cover;
		}

		&.region-980 {
			.region-img {
				background-image: url(RESOURCE/img/region-borkum-a-z.webp);
			}
		}

		&.region-981 {
			.region-img {
				background-image: url(RESOURCE/img/region-tile-juist-a-z.webp);
			}
		}

		&.region-979 {
			.region-img {
				background-image: url(RESOURCE/img/region-tile-wangerooge-a-z.webp);
			}
		}

		&.region-12530 {
			.region-img {
				background-image: url(RESOURCE/img/region-langeoog.webp);
			}
		}


	}

	.region-bl {
		border: 1px solid var(--color-grey-normal);
		border-radius: var(--border-radius);
		margin-bottom: 30px;
		min-height: 400px;
		overflow: hidden;
		position: relative;
		transform: scale(1);
		transition: all .2s;

		&:hover {
			transform: scale(1.015);
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
		}

		.fix-region-url {
			position: absolute;
			inset: 0;
			z-index: 1;

		}

		h4 {
			color: var(--color-black);
		}

		.region-content-bl {
			padding: 10px;
		}

		.region-img {
			height: 250px;
			margin-bottom: 15px;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.regioinfoslist-bl {
		margin-bottom: 20px;
		background: #fff;
		border: solid 1px #e3e4e6;
		padding: 25px;
		border-radius: 5px;

		h3 {
			margin-bottom: 5px;
		}

		ul {
			width: 100%;
			margin: 5px 0;
			padding: 0;

			li {
				list-style: none;
				width: 100%;
				position: relative;
				font-size: 13px;
				line-height: 20px;
				padding: 10px 10px 10px 52px;

				h6 {
					margin-bottom: 0;
				}

				&:before {
					content: "";
					background-image: url(RESOURCE/img/regioinfosicons.png);
					background-repeat: no-repeat;
					position: absolute;
					width: 32px;
					height: 32px;
					left: 3px;
					top: 12px;
					border-radius: 3px;
				}

				&.regicon1:before {
					background-position: 0 0;
				}

				&.regicon2:before {
					background-position: 0 -40px;
				}

				&.regicon3:before {
					background-position: 0 -80px;
				}

				&.regicon4:before {
					background-position: 0 -120px;
				}

				&.regicon5:before {
					background-position: 0 -160px;
				}

				&.regicon6:before {
					background-position: 0 -200px;
				}

				&.regicon7:before {
					background-position: 0 -240px;
				}
			}

		}
	}

}