.static-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 170px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	#description {
		p {
			display: none;

			&:first-child {
				display: block;
			}
		}

		h2 {
			display: none;
		}

		&.show-all {
			margin-bottom: 50px;

			p {
				display: block;
			}

			h2 {
				display: block;
			}
		}
	}

	#showMoreLink {
		margin-bottom: 50px;
		text-align: center;
	}



	.inner-banner {
		background-position: center;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 10px;
			background-color: var(--color-white);
			border-radius: 15px 15px 0 0;
		}

		@media (max-width:992px) {
			height: 250px;
		}


		&.partner {
			background-image: url(RESOURCE/img/inner-banner-unsere-partner.webp);
		}

		&.wfv-gmbh {
			background-image: url(RESOURCE/img/inner-banner-wfv.webp);
		}

		&.verkauf {
			background-image: url(RESOURCE/img/inner-banner-verkauf.webp);

		}

		&.vermieter {
			background-image: url(RESOURCE/img/inner-banner-vermieter-werden.webp);
			background-position: top;
		}

		&.wfv-stellt {
			background-image: url(RESOURCE/img/inner-banner-titelbild.webp);
		}

		&.jobs {
			background-image: url(RESOURCE/img/inner-banner-job.webp);
		}

		&.newsletter {
			background-image: url(RESOURCE/img/inner-banner-newsletter.webp);
		}

		&.service-bueros {
			background-image: url(RESOURCE/img/inner-banner-service-bueros.webp);
		}

		&.langeoog {
			background-image: url(RESOURCE/img/inner-banner-langeoog2.webp);
		}

		/*&.verwaktung {
			background-image: url(RESOURCE/img/inner-banner-team.webp);
		}*/
		&.verwaktung {
			background-image: url(RESOURCE/img/inner-banner-unsere-hausverwaltung.webp);
		}

		&.family {
			background-image: url(RESOURCE/img/inner-banner-family.webp);
		}

		&.hausverwaltung {
			background-image: url(RESOURCE/img/inner-banner-hausverwaltung.webp);
		}

		&.luxuswohnungen {
			background-image: url(RESOURCE/img/inner-banner-exklusiv.webp);
		}

		&.objektservice {
			background-image: url(RESOURCE/img/inner-banner-objektservice.webp);
		}

		&.reinigungsservice {
			background-image: url(RESOURCE/img/inner-banner-reinigungsservice.webp);
		}

		&.renovierung {
			background-image: url(RESOURCE/img/inner-banner-renovierung-umbau.webp);
		}

		&.hund {
			background-image: url(RESOURCE/img/inner-banner-mit-hund.webp);
		}

		&.verkaufen {
			background-image: url(RESOURCE/img/inner-banner-sie-wollen-verkaufen.webp);
		}

		&.immobilien {
			background-image: url(RESOURCE/img/inner-banner-immobilien.webp);
		}

		&.disclaimer {
			background-image: url(RESOURCE/img/inner-banner-haftungsausschluss.webp);
		}
	}

	.feature-tile-bl {
		h3 {
			margin-top: 20px;
			color: #fff;
			position: relative;

			@media (max-width:1300px) and (min-width:993px) {
				font-size: 20px;
			}
		}

		p {
			font-size: 16px;
			color: #fff;
			line-height: 24px;
			position: relative;
			margin: 0 auto;

			@media (max-width:1300px) and (min-width:993px) {
				font-size: 14px;
				line-height: 20px;
			}
		}

		.btn-primary {
			position: relative;
		}

		.feature-tile-block {
			position: relative;
			width: 100%;
			margin-bottom: 30px;
			border-radius: var(--border-radius);
			overflow: hidden;
			position: relative;
			inset: 0;
			transform: scale(1);
			transition: all ease .2s;

			&.partner {
				.img-block {

					img {
						object-fit: contain;
					}
				}
			}

			&:hover {
				transform: scale(1.05);

				@media (min-width:993px) {
					.feat-title {
						bottom: -100px;
					}

					.feature-content {
						bottom: 0;
					}

					.feature-content,
					.feat-text-link {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.feat-link {
				position: absolute;
				inset: 0;
				z-index: 2;

			}

			.feat-text-link {
				position: absolute;
				bottom: 40px;
				right: 50px;
				transition: bottom 0.3s ease-in-out;
				visibility: hidden;
				opacity: 0;
				color: #fff;
				text-transform: capitalize;
				font-size: 18px;
				font-weight: bold;

				@media (max-width:992px) {
					visibility: visible;
					opacity: 1;

				}

				@media (max-width:1300px) and (min-width:993px) {
					font-size: 16px;
				}
			}

			.feat-title {
				transition: all 0.3s ease-in-out;
				display: inline-block;
				position: absolute;
				z-index: 1;
				color: var(--btn-primary-font-color);
				background-color: var(--btn-primary-bg-color);
				left: 30px;
				bottom: 30px;
				transition: bottom 0.3s ease-in-out;
				padding: 10px 20px;
				font-weight: bold;

				@media (max-width:992px) {
					display: none;
				}
			}

			.feature-content {
				background-color: rgba(26, 121, 187, .85);
				padding: 20px;
				position: absolute;
				height: 100%;
				bottom: -100%;
				visibility: hidden;
				opacity: 0;
				transition: all 0.3s ease-in-out;
				color: #fff;

				@media (max-width:992px) {
					background-color: rgba(26, 121, 187, .75) !important;
					bottom: inherit;
					bottom: 0 !important;
					visibility: visible !important;
					opacity: 1 !important;
				}

				@media (max-width:1300px) and (min-width:993px) {
					font-size: 14px;
				}
			}

			.img-block {
				height: 335px;

				@media (max-width:992px) {
					height: 410px;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

		}

	}

	.iframe-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		/* 16:9 */
		padding-top: 25px;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.die-wfv-text-bl {
		.row {
			background-color: #eaeffb;
			padding: 15px 0;
		}

		.die-wfv-heading {
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			.die-wfv-img {
				width: auto;
				height: 35px;
				display: inline-block;
				margin-right: 10px;
			}

			span {
				font-size: 35px;
				color: var(--color-primary);
				font-weight: bold;
			}
		}

		.die-wfv-text {
			text-align: center;
			margin: 20px 0;
			line-height: 1;

			p {
				margin: 0;
			}

			.heading-num {
				font-size: 48px;
				color: var(--color-primary);
				font-weight: bold;
			}
		}
	}

	.paragraph-blue {
		color: #1a79bb;
	}

	.image-cover {
		img {
			border-radius: 5px;
		}
	}

	.job-box-bl {
		display: block;
		padding: 30px;
		background: var(--color-secondary);
		color: var(--color-white);
		text-decoration: none;
		margin-bottom: 30px;

		span {
			text-decoration: none;
			font-size: 14px;
		}

		p {
			color: var(--color-white);
			text-decoration: none;
			font-size: 18px;
			min-height: 53px;
		}
	}


	.parnter-logo-bl {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f5f5f5;
		width: 100%;
		height: 150px;
		padding: 15px;
		margin-bottom: 30px;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	ul {
		&.list-li {
			list-style: disc;
			padding-left: 15px;
		}

		li {


			i {
				margin-right: 5px;
				position: relative;
				top: 2px;
				left: 0;

			}
		}

		a {
			text-decoration: underline;
		}

	}
}