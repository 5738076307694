.unit-features-v1 {

	.feature-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--border-radius);
		border: 1px solid var(--color-grey-dark);
		background-color: var(--color-grey-light);


		@media(max-width:767px) {
			flex-wrap: wrap;
			padding: 15px;
		}

		@media(min-width:768px) {
			padding-top: 15px;
		}

		.ul-nopints {
			max-width: 100%;
			margin-bottom: 0;
			display: flex;
			flex-wrap: wrap;

			@media(max-width:767px) {
				max-width: 100%;
			}

			li {
				display: block;
				text-align: center;
				font-size: 14px;
				margin-bottom: 5px;

				@media(min-width:768px) {
					width: 33%;
					margin-bottom: 15px;
				}

				@media(min-width:992px) {
					width: 20%;
					margin-bottom: 15px;
				}


				@media(max-width:767px) {

					display: inline-block;
					margin-right: 5px;
					width: 45%;
					text-align: left;
				}

				@media(max-width:560px) {
					font-size: 14px;
				}


				&.full-width {
					@media(max-width:767px) {
						width: 100%;
					}
				}

				i {
					@media (min-width:768px) {
						font-size: 25px;
						width: 100%;
						text-align: center;
						margin-bottom: 6px;
					}

					&.text-green {
						color: var(--color-secondary);
					}

					@media(max-width:767px) {
						/*margin-right: 5px;*/
						width: 25px;
					}
				}

				.fa-stack {
					@media (min-width:768px) {
						margin: 0 auto;
						display: block;
						margin-bottom: 3px;
					}

					@media(max-width:767px) {
						width: 25px;
					}
				}
			}
		}

		.rating {
			i {
				color: var(--color-secondary);
			}
		}
	}

	.svg-div {
		@media(max-width: 767px) {
			display: inline-block;
		}

		.svg {
			width: 23px;
			height: auto;

			@media(max-width: 767px) {
				width: 14px;
				margin-right: 9px;
			}

			@media(min-width: 768px) {
				margin-bottom: 7px;
			}

		}
	}
}