.searchbar-v1 {
	position: absolute;
	bottom: 1.5rem;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	z-index: 6;

	&.hide {
		z-index: 4;
	}

	@media (max-width:767px) {
		bottom: 16rem;
	}

	.search-mob-btn {
		position: relative;
		z-index: 5;

		.btn-search-box {
			background-color: rgba(255, 255, 255, .9);
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px;
			font-size: 16px;

			.trigger-icon-bl {
				.fa {
					padding-right: 5px;
				}

				.fa-search {
					color: var(--color-primary);
					font-size: 20px;
				}
			}
		}
	}

	.quick-btns {
		.btn {
			margin: 5px;

			@media(max-width: 992px) {
				font-size: 14px;

			}

			i {
				margin-right: 3px;

				.icon-fire {
					@media(max-width:992px) {
						width: 18px;
					}
				}

				@media(max-width:767px) {
					display: none;
				}
			}

			&.btn-offer {
				i {
					margin-right: 5px;
				}
			}



			&:hover {

				.icon-fire,
				.icon-percent {
					filter: brightness(0) saturate(100%) invert(37%) sepia(79%) saturate(860%) hue-rotate(172deg) brightness(88%) contrast(91%);
				}

			}

			@media(max-width: 345px) {
				font-size: 12px;
				padding: 5px 10px;
			}
		}
	}

	.container {
		@media (min-width: 1240px) {
			max-width: 1040px;
		}
	}

	.second-level {
		padding-left: 30px;
	}

	.tabs {
		padding-left: 35px;

		.tab {
			background-color: var(--color-primary);
			color: #fff;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 6px 20px;
			opacity: 1;
			cursor: pointer;
			display: inline-block;

			@media(max-width: 345px) {
				padding: 2px 10px;
			}


			&.active {

				color: var(--color-primary);
				background-color: var(--color-white);
			}

		}

	}

	#search-obj-form {
		display: none;
	}

	.form-flex-area {
		position: relative;

		&.region-bl {
			.box {
				height: auto !important;
				max-height: inherit !important;
			}
		}
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: rgba(255, 255, 255, .9);
		flex-flow: wrap;
		border-radius: 8px;
		margin: 0px 0px;

		.input-group {
			padding: 0px;
		}

		.form-btn {
			margin-left: 0px !important;
		}

		.searchbox-obj {
			padding-top: 4px;
			padding-left: 12px;
		}


		.form-obj-flex {
			width: 90%;
			padding: 9px 15px;
			border-right: 1px solid #f4f4f4;

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 18px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				padding: 18px 5px 18px 36px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
			}



		}

		.form-flex {
			width: 30%;
			padding: 9px 15px;
			border-right: 1px solid #f4f4f4;


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa {
				position: absolute;
				left: 10px;
				top: 18px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 5px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: none;
				padding: 18px 5px 18px 30px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 5px;
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding-bottom: 5px;
			}

			.btn {
				width: 101%;
				height: 65px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				border: none;
				transition: all ease .2s;

				&.btn-primary {
					@media (min-width:993px) {
						background-color: transparent;
						color: var(--color-primary);
					}
				}

				&:hover {
					font-size: 30px;
				}

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.col-half-left {
		width: 50%;
		float: left;
	}

	.list-group {
		display: block;
		font-size: 14px;

	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;
		padding-top: 4px;
		padding-bottom: 4px;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}