.search-page-layout-v1 {
	margin-top: var(--page-margin-top);
	position: relative;

	.list-view {
		@media(max-width: 767px) {
			min-height: 850px;
		}
	}

	#searchstart {
		position: relative;
		min-height: 98px;

		@media(max-width: 380px) {
			min-height: 145px;
		}
	}

	.share {
		position: absolute;
		top: 15px;
		right: 15px;
		font-size: var(--font-size-md);
		color: var(--color-primary);
		cursor: pointer;

		&:hover {
			color: var(--color-secondary);
		}
	}

	.gradient {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 170px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.45) 50%, rgba(255, 255, 255, 0) 100%);
		z-index: 1;
	}

	.search-banner {
		background-image: url(RESOURCE/img/inner-banner-job.webp);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 200px;
		position: relative;
		/*border-radius: 50px 50px 0 0;*/
		width: 100%;
		background-position: center top;

		&:after {
			content: "";
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 10px;
			background-color: var(--color-white);
			border-radius: 15px 15px 0 0;
		}

		&.exclusive {
			background-image: url(RESOURCE/img/inner-header-exklusiv.webp);
			background-position: center bottom;
		}

		&.pets {
			background-image: url(RESOURCE/img/inner-header-hund.webp);
			background-position: center bottom;
		}

		&.family {
			background-image: url(RESOURCE/img/inner-header-familie.webp);
			background-position: center;
		}

		&.sauna {
			background-image: url(RESOURCE/img/inner-header-sauna.webp);
			background-position: center bottom;
		}

		&.meerblick {
			background-image: url(RESOURCE/img/inner-header-meerblick.webp);
			background-position: center bottom;
		}

		&.angebote {
			background-image: url(RESOURCE/img/inner-banner-job.webp);
		}

		&.new {
			background-image: url(RESOURCE/img/inner-banner-job.webp);
		}
	}

	.dummyMap {
		width: 100px;
		height: 100px;
	}

	.search-title {
		margin-top: 16px;
		padding-right: 55px;
	}

	.btn {
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;
		margin-bottom: 30px;

		@media (min-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		@media (max-width:991px) {
			grid-template-columns: 1fr;
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

	.close {
		float: right;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 0.8;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		opacity: .5;
		border: none;
		background: transparent;

		&:hover {
			opacity: .8;
		}
	}


}

.my-search-token {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		text-align: center;
		background-color: #ff9a00;
		background-color: var(--color-primary);
		color: #fff;
		border: 1px solid var(--color-grey-light);
		border-radius: 50%;
		font-weight: 400;
		opacity: 0;
		padding: 0;

		div {
			margin-top: -6px;
		}
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}

.photo-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.photo {
	width: 49%;
	margin-bottom: 20px;

	@media(max-width: 768px) {
		width: 99%;
	}

	img {
		width: 100%;
		height: auto;
	}
}