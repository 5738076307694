@import "mixin.css";

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}

.flex {
	@mixin flex;
}

.size100 {
	@mixin size100;
}

.bg-blue-strip {
	background-color: #eaeffb;
}

@media (-webkit-min-device-pixel-ratio: 1.1),
(-webkit-min-device-pixel-ratio: 1.09375),
(min-resolution: 105dpi),
(min-resolution: 1.1dppx) {

	/* Serve SVG sprite if browser supports SVG and resolution is more than 105dpi */
	.pswp--svg .pswp__button,
	.pswp--svg .pswp__button--arrow--left:before,
	.pswp--svg .pswp__button--arrow--right:before {
		background-image: url(RESOURCE/img/default-skin.png) !important;
	}

	.pswp--svg .pswp__button--arrow--left,
	.pswp--svg .pswp__button--arrow--right {
		background: none !important;
	}


}

.icon-360 {
	background-image: url(RESOURCE/img/icon-360.svg);
	height: 18px;
	width: 30px;
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.checkbox {

	label {

		&:before,
		&:after {
			top: 6px;
		}
	}
}

.text-right {
	text-align: right;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
	border-right-color: var(--color-grey-normal) !important;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
	border-bottom-color: var(--color-grey-normal) !important;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
	border-left-color: var(--color-grey-normal) !important;
}

.tooltip {
	.tooltip-arrow {
		&:before {
			border-top-color: var(--color-grey-normal) !important;

		}
	}

	.tooltip-inner {
		background-color: var(--color-grey-normal);
		color: var(--color-black);
	}
}


/* fade in up */

.asd__wrapper {
	margin: 0 auto;
}

.img-responsive {
	max-width: 100%;
}

.font-big {
	font-size: 20px;
	font-weight: bold;
}

.bg-grey-strip {
	background-color: var(--color-grey-normal);
}





.breadcrumb {
	padding: 10px 0;

}

.fa-magnifying-glass-plus:before,
.fa-search-plus:before {
	content: "\f00e";
}

.fa-fire:before {
	content: "\f06d";
}

.icon-fire {
	background-image: url(RESOURCE/img/icon-fire-regular.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
	display: inline-block;
	filter: invert(1);
	position: relative;
	top: 4px;

	@media(max-width: 345px) {
		width: 14px;
		height: 14px;
	}
}

.icon-percent {
	background-image: url(RESOURCE/img/icon-percent.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
	filter: invert(1);
	display: inline-block;
	position: relative;
	top: 4px;

	@media(max-width: 345px) {
		width: 14px;
		height: 14px;
	}
}

p {
	a {
		text-decoration: underline;
	}
}

html,
body {
	font-family: var(--font-family-main);
	color: var(--font-color-main);
	font-size: var(--font-size-main);
	font-weight: var(--font-weight-main);

}


.checkbox input[type=checkbox]:checked+label:after {
	font-family: 'Font Awesome 6 Pro' !important;
	content: "\f00c";
	font-weight: bold !important;
}

body.modal-open {
	overflow: hidden;
}

h1,
.h1 {
	font-family: var(--h1-font-family);
	font-weight: var(--h1-font-weight);
	font-size: var(--h1-font-size);
	color: var(--h1-font-color);
	text-transform: var(--h1-text-transform);
	text-decoration: var(--h1-text-decoration);
}

.dropdown-toggle:after {
	margin-left: 10px;
	position: absolute;
	right: 0;
	display: inline-block;

	@media (min-width:993px) {

		display: none;
	}
}


.h1-width-small {
	line-height: var(--h1-font-size);

	small {
		font-size: var(--font-size-main);
		text-transform: none;
		display: inline-block;
	}
}

h2,
.h2 {
	font-family: var(--h2-font-family);
	font-weight: var(--h2-font-weight);
	font-size: var(--h2-font-size);
	color: var(--h2-font-color);
	text-transform: var(--h2-text-transform);
	text-decoration: var(--h2-text-decoration);
}

h3,
.h3 {
	font-family: var(--h3-font-family);
	font-weight: var(--h3-font-weight);
	font-size: var(--h3-font-size);
	color: var(--h3-font-color);
	text-transform: var(--h3-text-transform);
	text-decoration: var(--h3-text-decoration);
}

h4,
.h4 {
	font-family: var(--h4-font-family);
	font-weight: var(--h4-font-weight);
	font-size: var(--h4-font-size);
	color: var(--h4-font-color);
	text-transform: var(--h4-text-transform);
	text-decoration: var(--h4-text-decoration);
}

h5,
.h5 {
	font-family: var(--h5-font-family);
	font-weight: var(--h5-font-weight);
	font-size: var(--h5-font-size);
	color: var(--h5-font-color);
	text-transform: var(--h5-text-transform);
	text-decoration: var(--h5-text-decoration);
}

.heading1 {
	position: relative;
	text-align: center;
	padding-bottom: 40px;

	&.text-left {
		text-align: left;

		&:before {
			left: 0;
			transform: inherit;
		}
	}


	&:before {
		content: "";
		position: absolute;
		bottom: 11px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 15%;
		height: 1px;
		background-color: var(--color-primary);

		@media(max-width: 992px) {
			width: 20%;
		}

		@media(max-width: 768px) {
			width: 25%;
		}

		@media(max-width: 576px) {
			width: 30%;
		}
	}
}

a {
	color: var(--link-font-color);
	text-decoration: var(--link-text-decoration);

	&:hover {
		text-decoration: var(--link-text-decoration-hover);
		color: var(--link-font-color-hover);
	}
}

ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
}

.form-control:focus {
	box-shadow: none;
}

.btn:focus,
.btn:active {
	box-shadow: none;
	text-decoration: none;
}

/*--- background-colors---*/

.bg-primary {
	background-color: var(--color-primary) !important;
}

.bg-primary-light {
	background-color: var(--color-primary-light) !important;
}

.bg-secondary {
	background-color: var(--color-secondary) !important;
}

.bg-secondary-light {
	background-color: var(--color-secondary-light) !important;
}

.bg-white {
	background-color: var(--color-white) !important;
}

.bg-grey-light {
	background-color: var(--color-grey-light) !important;
}

.bg-grey-normal {
	background-color: var(--color-grey-normal) !important;
}

.bg-grey-dark {
	background-color: var(--color-grey-dark) !important;
}

.bg-black {
	background-color: var(--color-black) !important;
}

/*--- font-colors---*/

.color-primary {
	color: var(--color-primary) !important;
}

.color-primary-light {
	color: var(--color-primary-light) !important;
}

.color-secondary {
	color: var(--color-secondary) !important;
}

.color-secondary-light {
	color: var(--color-secondary-light) !important;
}

.color-white {
	color: var(--color-white) !important;
}

.color-grey-light {
	color: var(--color-grey-light) !important;
}

.color-grey-normal {
	color: var(--color-grey-normal) !important;
}

.color-grey-dark {
	color: var(--color-grey-dark) !important;
}



.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

/*--- Datepicker ---*/

.datepicker-trigger {

	.asd__day--disabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--cal-selection-range) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}


	.asd__day--disabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--cal-selection-range) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;
	}

	.asd__day--enabled {
		background-color: var(--cal-available) !important;
		color: var(--cal-text-color) !important;

		&.asd__day--disabled {
			background-color: var(--cal-disabled) !important;
			opacity: 0.8 !important;
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--cal-selected) !important;
			}

			&.asd__day--in-range {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			}

			/*	&.asd__day--hovered {
				background: var(--cal-selection-range) !important;
				opacity: 1 !important;
				border: 1px double var(--cal-selected-border) !important;
			} */
		}


	}

	.asd__day--enabled:not(.asd__day--disabled) {
		&:hover {
			background: var(--cal-selected) !important;
			opacity: 1 !important;
			color: var(--cal-text-color-hover) !important;
		}
	}

	.asd__day--selected {
		background: var(--cal-selected) !important;
		color: var(--cal-text-color-selected) !important;
		opacity: 1 !important;
		border: 1px double var(--cal-selected-border) !important;

		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--cal-selection-range);
				border-bottom: 20px solid var(--cal-selection-range);
				left: 0px;
			}
		}

	}
}

/*--- Buttons ---*/

.btn {
	border-radius: var(--btn-border-radius);
	font-size: var(--btn-font-size);
	/*	padding: 8px 16px; */
	text-transform: var(--btn-text-transform);
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	&.btn-primary,
	&.btn-go {
		background-color: var(--btn-primary-bg-color);
		color: var(--btn-primary-font-color);
		border-color: var(--btn-primary-border-color);

		&:hover {
			background-color: var(--btn-primary-bg-color-hover);
			border-color: var(--btn-primary-border-color-hover);
			color: var(--btn-primary-font-color-hover);
		}
	}

	&.btn-secondary {
		background-color: var(--btn-secondary-bg-color);
		color: var(--btn-secondary-font-color);
		border-color: var(--btn-secondary-border-color);

		&:hover {
			background-color: var(--btn-secondary-bg-color-hover);
			border-color: var(--btn-secondary-border-color-hover);
			color: var(--btn-secondary-font-color-hover);
		}
	}

	&.btn-black {
		background-color: var(--color-black);
		color: var(--color-white);
		border-color: var(--color-white);

		&:hover {
			background-color: var(--color-white);
			border-color: var(--color-black);
			color: var(--color-black);
		}
	}

	&.btn-default {
		background-color: var(--btn-default-bg-color);
		color: var(--btn-default-font-color);
		border-color: var(--btn-default-border-color);

		&:hover {
			background-color: var(--btn-default-bg-color-hover);
			border-color: var(--btn-default-border-color-hover);
			color: var(--btn-default-font-color-hover);
		}
	}

	&.btn-add {
		background-color: var(--btn-add-bg-color);
		color: var(--btn-add-font-color);
		border-color: var(--btn-add-border-color);

		&:hover {
			background-color: var(--btn-add-bg-color-hover);
			border-color: var(--btn-add-border-color-hover);
			color: var(--btn-add-font-color-hover);
		}
	}

	&.btn-remove {
		background-color: var(--btn-remove-bg-color);
		color: var(--btn-remove-font-color);
		border-color: var(--btn-remove-border-color);

		&:hover {
			background-color: var(--btn-remove-bg-color-hover);
			border-color: var(--btn-remove-border-color-hover);
			color: var(--btn-remove-font-color-hover);
		}
	}

	&.btn-bc {
		background-color: var(--btn-breadcrumb-bg-color);
		color: var(--btn-breadcrumb-font-color);
		border-color: var(--btn-breadcrumb-border-color);

		&:hover {
			background-color: var(--btn-breadcrumb-bg-color-hover);
			border-color: var(--btn-breadcrumb-border-color-hover);
			color: var(--btn-breadcrumb-font-color-hover);
		}
	}

	&.btn-sm {
		font-size: var(--font-size-sm);
		padding: 6px 12px;
	}

	&.btn-lg {
		height: 46px;
		font-size: var(--font-size-lg);
		padding: 10px 16px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}

	&.btn-arrow {
		min-width: 220px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width:992px) {
			min-width: 160px;
		}

		.fa {
			opacity: .4;
			margin-left: 15px;
		}
	}
}


.btn-breadcrumb .btn:not(:last-child):after {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid white;
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn:not(:last-child):before {
	content: " ";
	display: block;
	width: 0;
	height: 0;
	border-top: 17px solid transparent;
	border-bottom: 17px solid transparent;
	border-left: 10px solid rgb(173, 173, 173);
	position: absolute;
	top: 50%;
	margin-top: -17px;
	margin-left: 1px;
	left: 100%;
	z-index: 3;
}

.btn-breadcrumb .btn {
	padding: 6px 12px 6px 24px;

	&.disabled {
		opacity: 0.6;
		color: #000;

	}
}

.btn-breadcrumb .btn:first-child {
	padding: 6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
	padding: 6px 18px 6px 24px;
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):after {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}

.btn-breadcrumb .btn.btn-bc:hover:not(:last-child):before {
	border-left: 10px solid var(--btn-breadcrumb-bg-color-hover);
}



.breadcrumb li a:hover:after {
	border-left-color: #ffc107 !important;
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 41%;
		right: 0px;
		width: 100%;

		@media (max-width:992px) {
			display: none;
		}

		button {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--color-secondary) !important;
			position: absolute;
			left: 0;
			text-align: center;


			span {
				font-family: "Font Awesome 6 Pro";
				font-weight: 900;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: var(--color-white);


			}

			&.owl-next {
				position: absolute;
				right: 2px;
				left: inherit;

				@media (max-width:992px) {
					right: 0;
				}

			}

			&.owl-prev {
				left: 2px;

				@media (max-width:992px) {
					left: 0;
				}

			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-unit {
	margin: 0 auto 10px;
	max-width: 500px;
	transform: scale(1);
	transition: all ease .2s;

	&:hover {
		transform: scale(1.015);
	}

}

.single-units {
	padding: 4px 3px;
}


.unit-list-section {
	.owl-carousel {
		padding: 15px;

		/*.owl-item {
			margin-right: 15px !important;
		}*/

		.single-units {
			max-width: 286px !important;
			width: 100% !important;
		}

		overflow: hidden;

		.owl-stage-outer {
			overflow: visible;
		}
	}
}


.owl-carousel {
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 0 0;

		@media (max-width: 767px) {
			margin: 30px 0 0 0;
		}

		.owl-dot {
			width: 15px;
			height: 15px;
			background: #b5b5b5;
			margin: 0 5px;
			border-radius: 100px;

			&.active {
				background: var(--color-primary);
			}
		}
	}
}

.owl-carousel.off {
	display: block;
}

.payment-view {
	margin-top: var(--page-margin-top);
	padding-top: 30px;
	padding-bottom: 60px;
}

.inner-banner {
	background-image: url(RESOURCE/img/banner1.webp);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 525px;
	position: relative;
	/*border-radius: 50px 50px 0 0;
	height: 250px;*/
	background-position: center top;


	.pag-title {
		transition: all .3s ease-in-out;
		z-index: 1;
		color: var(--btn-primary-font-color);
		background-color: var(--btn-primary-bg-color);
		transition: bottom .3s ease-in-out;
		padding: 10px 20px;
		font-weight: 700;
		display: inline-block;
		bottom: 30px;
		position: absolute;

		h1 {
			font-size: var(--font-size-main);
			color: var(--color-white);
			margin-bottom: 0;
		}

		/*@media (max-width:992px) {
			bottom: 30px;
		}


		.pag-title {
			display: inline-block;
			font-size: 36px;
			margin-bottom: 0;
			color: var(--color-white);
			text-transform: uppercase;
			padding: 10px;
			background-color: var(--color-primary);

			@media (max-width:992px) {
				font-size: 24px;
			}
		}*/
	}
}

.privacy-link {
	text-decoration: underline;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.googleMapsHint {
	color: var(--googlemaps-hint-text-color);
	font-size: var(--googlemaps-hint-font-size);
	width: 100%;
}

.no-google-map {
	width: 100%;
	height: 150px;
	position: relative;
}

.box {
	position: absolute;
	z-index: 999 !important;
}

.datepicker-ext-row {
	display: flex;
	align-items: center;
	margin: 20px 0 0 0 !important;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		max-width: 280px;
		justify-content: center;
		width: 100%;
		margin: 30px auto 0 !important;
	}

	span {
		font-size: 15px;

		@media (max-width: 767px) {
			margin: 0 0 15px 0;
			max-width: 90px;
			display: inline-block;
			width: 100%;
		}
	}

	select {
		height: 40px;
		max-width: 130px;
		width: 100%;
		border-radius: 0;
		padding-left: 5px;
		padding-right: 5px;
		margin: 0 20px 0 10px;
		border: 1px solid rgba(0, 0, 0, .2);
		background-position: 96% 16px;

		@media (max-width: 767px) {
			margin: 0 20px 10px 10px;
			max-width: 160px;
		}

		@media all and (min-width: 400px) and (max-width: 480px) {
			max-width: 180px;
		}
	}
}




.pswp__caption__center {
	text-align: center !important;
}

.hide-mob {
	display: block;

	@media (max-width:767px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}

.hide-tab-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-tab-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}



/*

.fal {
	font-family: 'Font Awesome 5 Free' !important;
	font-weight: 300;
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;

}*/



.popup-header {
	font-size: var(--popup-header-font-size);
	color: var(--popup-header-color);
	font-family: var(--popup-header-font-family);
}

.modal-dialog {
	max-width: 800px;
	margin: 30px auto;
	width: 100%;
}

.modal-custom {
	display: none;
	position: fixed;
	z-index: 99;
	padding-top: 20px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);

}

/* Modal Content */
.modal-custom-dialog {
	background-color: #fff;
	margin: auto;
	padding: 0px 10px;
	border: 1px solid #888;
	width: 90%;
	max-width: 1150px;
	border-radius: var(--border-radius);


	.close {
		color: #777;
		position: absolute;
		width: 100%;
		font-size: 26px;
		height: 30px;
		width: 30px;
		right: -5px;
		text-align: center;
		line-height: 30px;
		top: 4px;
		font-weight: 400;
		font-family: arial, helvetica, sans-serif;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

}

.modal-custom-header {
	height: 35px;
	text-align: right;
	position: relative;
}

.modal-custom-footer {
	height: 35px;
	text-align: center;
	padding-right: 20px;
	padding-top: 5px;

	@media (min-width: 992px) {
		text-align: right;
	}
}

.modal-custom-content {
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 20px;
}

/* The Close Button */

.hide-576 {
	@media (max-width: 576px) {
		display: none;
	}
}

.hide-767 {
	@media (max-width: 767px) {
		display: none;
	}
}

.show-only-767 {
	display: none;

	@media (max-width: 767px) {
		display: block;
	}
}

.hide-991 {
	@media (max-width: 991px) {
		display: none;
	}
}

.show-only-991 {
	display: none;

	@media (max-width: 991px) {
		display: block;
	}
}

.relative {
	position: relative;
}

.visible-print {
	display: none !important;
}

.openstreetwarning {
	position: relative;
	background-color: #000;
	aspect-ratio: 16/9;
	padding: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;

	@media(max-width:500px) {
		padding: 5px;
		font-size: 13px;
	}

	.text {
		text-align: center;
	}

	.button-line {
		text-align: center;
		margin: 10px 0px;

		@media(max-width:500px) {
			margin: 0px 0px;
		}
	}

	a {
		color: var(--color-white);
	}
}

.subtitle {
	padding-bottom: 17px;
	font-size: 20px;
	margin-top: -17px;
	color: var(--color-primary);
	font-weight: 400;
	text-indent: 5px;
	position: relative;
}

.tooltip-custom {
	position: relative;
}

.tooltip-custom .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: var(--border-radius);
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip-custom .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

#cboxPrevious,
#cboxNext {
	background-image: url(RESOURCE/img/controls3.png) !important;

}

#cboxClose {
	background-image: url(RESOURCE/img/controls3.png) !important;
	top: 4px !important;

}

#cboxPrevious {
	left: 4px !important;
}

#cboxNext {
	right: 4px !important;
}

#cboxOverlay {
	background: #000;
}

#cboxContent,
.cboxIframe {
	margin: 0 auto;
}


@media (min-width:1240px) {
	.container {
		max-width: 1240px;
	}
}

@media (max-width: 767px) {
	.container {
		max-width: 720px;
	}
}

@media print {
	.visible-print {
		display: block !important;
	}

}

.modal-dialog {
	&.favoriten {
		max-width: 400px;
	}

	.intro {
		padding-bottom: 15px;
	}

	.btn-line {
		margin-bottom: 15px;

		.btn {
			position: relative;

			.copylink {

				&.active {
					&:before {
						content: "\f00c";
						font-family: Font Awesome\ 6 Sharp;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						position: absolute;
						left: 15px;
						top: 5px;
					}

					&:hover {
						color: #FFF;
					}
				}
			}

			.icon {
				position: absolute;
				right: 15px;
				top: 10px;


			}
		}
	}


}