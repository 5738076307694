.footer-v1 {
	background-color: var(--footer-bg-color);
	color: var(--footer-font-color);
	border-top: 1px solid var(--footer-border-top-color);


	.main-footer {
		a {
			@media (max-width:1440px) {
				font-size: 14px;
			}
		}

		&.style1 {
			background-color: #cccccc;
			color: var(--color-black);

			a,
			.headline {
				color: var(--color-black);
			}

			a {
				&:hover {
					color: var(--color-secondary);
				}
			}

			ul .footer-link i {
				color: var(--color-black);
			}
		}


		.kontact-bl {
			.fa {
				color: var(--color-white);
				padding-right: 5px;
			}
		}

		a {
			&:hover {
				text-decoration: none;
			}
		}

		ul {
			.footer-link {
				position: relative;
				list-style: none;
				margin: 10px 0;
				padding: 0 0 0 15px;

				i {
					position: absolute;
					left: 0;
					font-size: 14px;
					top: 7px;
					color: var(--color-white);
				}
			}
		}

		.topspace {
			margin-top: 60px;

			@media(max-width: 1240px) {
				margin-top: 50px;
			}

			@media(max-width: 992px) {
				margin-top: 30px;
			}

			@media(max-width: 767px) {
				margin-top: 0;
			}
		}
	}

	.main-footer {
		.foot-seo-text {
			p {
				font-size: 14px;
			}
		}

		.foot-address {
			p {
				font-size: 14px;
			}
		}

		.first-col {
			i {
				color: var(--color-white);
			}

			.social-icon {
				display: flex;

				i {
					color: var(--color-white);
					padding-right: 15px;
					font-size: 24px;
				}
			}
		}


		.text-muted {
			color: var(--color-grey-normal) !important;

		}

		padding-left: 5vw;
		padding-right: 5vw;

		position: relative;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);

			@media (max-width:1440px) {
				font-size: 18px;
			}

			@media (max-width:1240px) {
				font-size: 15px;
			}
		}


		.logo {
			width: 100px;
			height: auto;
			background-color: #fff;
			padding: 10px;
			border-radius: 5px;
		}

		.v-office-logo {

			background-color: var(--color-secondary);
			padding: 10px 20px;

			@media(min-width: 993px) {
				text-align: right;
				padding: 10px 60px;
			}

			@media(max-width: 992px) {
				text-align: left;
			}
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa,
				.fa-brands {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa,
			.fa-brands {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}

		.paymentmethods {
			width: 100%;
			text-align: right;
			margin-top: 30px;

			.fa,
			.fa-brands {
				color: var(--color-white);
				font-size: 1.8rem;
				padding-left: 10px;

			}
		}
	}


}