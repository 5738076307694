.themes-block {
	a {
		position: relative;
		border-radius: var(--border-radius);
		margin-bottom: 30px;
		overflow: hidden;
		display: block;

		/*&:hover {
			.tile-content-bl {
				height: 100%;
			}
		}*/
	}

	.tile-content-bl {
		position: absolute;
		background-color: var(--overlay-color);
		padding: 10px;
		bottom: 0;
		color: #fff;
		text-align: center;
		height: 48px;
		transition: 1s;
		width: 100%;

		.title {
			text-align: center;
			padding: 0 10px;
			font-size: 20px;
			font-weight: 400;
		}
	}

	.tile-img {
		display: block;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	a {
		text-decoration: none
	}


}